@import 'normalize.css';
img {
  max-width: 100%;
  height: auto;
}

.full-width, main section:nth-child(even) {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

.horizontal_center, .text_container, .container {
  margin-left: auto;
  margin-right: auto;
}

.container {
  max-width: 90%;
}

.text_container {
  max-width: 680px;
}

.grid {
  display: grid;
  grid-gap: 20px;
}

.responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang, Microsoft YaHei, Source Han Sans TC, Noto Sans CJK TC, WenQuanYi Micro Hei, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #424242;
  letter-spacing: 1px;
}

main section {
  padding: 60px 0;
}
main section:nth-child(even) {
  background: #fff9f2;
}
main section h2 {
  color: #424242;
  position: relative;
  margin: 0;
  padding: 24px 0 24px 48px;
  font-size: 1.75rem;
}
main section h2::before {
  content: url("https://alyssachan.space/logo.svg");
  width: 36px;
  height: 36px;
  display: block;
  position: absolute;
  left: 0;
  top: 20px;
}
main section .grid {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
main section .grid iframe {
  width: 100% !important;
}
main section .grid ul {
  list-style-type: none;
  padding: 0;
}
main section .grid ul li {
  margin-bottom: 20px;
}
main section .grid ul a {
  display: flex;
}
main section .grid ul svg {
  font-size: 48px;
  margin-right: 10px;
  color: #ffc87d;
}
main section .grid ul h4 {
  margin-top: 0;
  margin-bottom: 5px;
  color: #00b6b1;
}
main section img,
main section img + h3 {
  cursor: pointer;
}
main section p {
  color: #999;
}
main section a {
  color: #424242;
}
main section a:not(.cta):hover {
  color: #5c5c5c;
}
main section [aria-describedby^=popup-]:hover {
  opacity: 0.8;
}

a {
  text-decoration: none;
}
a:hover {
  opacity: 0.8;
}

p {
  line-height: 1.75;
}

:focus {
  outline: none;
}

.cta {
  display: block;
  width: fit-content;
  margin: 10px auto;
  padding: 10px 20px;
  background: #ffc87d;
  color: #fff;
  border-radius: 3px;
  text-align: center;
  white-space: nowrap;
}
.cta:hover {
  background: #ffb24a;
}

.preloader_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.clickable {
  cursor: pointer;
}

.profile_pic {
  width: 150px;
  height: 150px;
  margin: 10px auto;
}
.profile_pic img {
  object-fit: cover;
  border-radius: 50%;
}

header {
  background: #00b6b1;
  color: #fff;
  position: relative;
  height: 60px;
}
header.sticky {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
header.sticky + main {
  margin-top: 60px;
}
header * {
  line-height: 1;
}
header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
header h1 {
  margin: 0;
  font-size: 2rem;
}
header h1 a {
  color: #fff;
}
header nav {
  display: flex;
}
header button {
  background: #ffc87d;
  border: 0;
  border-radius: 3px;
  padding: 5px 10px;
  color: #424242;
  cursor: pointer;
}
header button:not(:first-child) {
  margin-left: 10px;
}
header button:hover {
  background: #ffb24a;
}

@media (max-width: 767.99px) {
  header nav {
    display: none;
    box-sizing: border-box;
    flex-direction: column;
    position: fixed;
    background: #a5e9d7;
    color: #00b6b1;
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    max-width: 100%;
    padding: 50px 50px 30px 30px;
    z-index: 1;
  }
  header nav.opened {
    display: flex;
  }
  header nav.opened + span {
    color: #00b6b1;
    position: fixed;
    top: 15px;
    right: 20px;
  }
  header nav a {
    color: #00b6b1;
    margin-bottom: 20px;
    font-size: 2.25rem;
    font-weight: 700;
  }
  header nav a + div {
    display: none;
  }
  header nav a + div + div {
    margin-top: 30px;
  }
  header span {
    z-index: 2;
    font-size: 36px;
  }
}
@media (min-width: 768px) {
  header a {
    color: #fff;
  }
  header nav {
    align-items: center;
    position: relative;
  }
  header nav > div:first-child:hover + div {
    display: flex;
  }
  header nav > div:last-child {
    display: none;
    position: absolute;
    right: -10px;
    top: 25px;
    background: #fff;
    padding: 20px;
    border-radius: 3px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  }
  header nav > div:last-child.opened {
    display: flex;
  }
  header nav > div:last-child::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    position: absolute;
    top: -6px;
    right: 13px;
  }
  header nav a {
    margin-right: 20px;
  }
  header span {
    display: none;
  }
}
section.portfolio .grid {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
}

.overlay_container {
  position: relative;
  background: #fff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.overlay_container a {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  position: relative;
}
.overlay_container img {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.overlay_container h4 {
  margin: 0;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-top: 2px solid #fff9f2;
}

@media (hover: hover) and (pointer: fine) {
  .overlay_container {
    background: transparent;
    box-shadow: none;
  }
  .overlay_container a,
.overlay_container h4,
.overlay_container img {
    border-radius: 3px;
  }
  .overlay_container h4 {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #fff;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border: 0;
  }
  .overlay_container:hover h4 {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffc87d;
    opacity: 0.9;
    z-index: 1;
  }
  .overlay_container:hover h4,
.overlay_container:hover img {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
  }
}
footer {
  background: #a5e9d7;
  padding: 10px 0;
  font-size: 1rem;
}
footer .container {
  display: flex;
  justify-content: center;
}
footer ul {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
footer ul [class^=icon-], footer ul [class*=" icon-"], footer ul [data-type=icon] {
  font-size: 1.5rem;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
footer ul [class^=icon-]:not(:hover)::before, footer ul [class*=" icon-"]:not(:hover)::before, footer ul [data-type=icon]:not(:hover)::before {
  color: #fff;
}
footer ul li {
  margin-right: 10px;
}

.top_arrow {
  display: none;
  color: #ffc87d;
  position: fixed;
  width: 36px;
  right: 20px;
  bottom: 50px;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}
.top_arrow:hover {
  opacity: 1;
}
.top_arrow.show {
  display: flex;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@media (max-width: 768px) {
  footer .container {
    flex-direction: column;
    text-align: center;
  }
  footer .container ul {
    justify-content: center;
    margin-bottom: 1rem;
  }
}
@media (min-width: 768px) {
  footer .container {
    justify-content: space-between;
    align-items: center;
  }
}