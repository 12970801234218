@charset "UTF-8";
@font-face {
  font-family: "icomoon";
  src: url("./src/fonts/icomoon.eot?l7nvgq");
  src: url("./src/fonts/icomoon.eot?l7nvgq#iefix") format("embedded-opentype"), url("./src/fonts/icomoon.ttf?l7nvgq") format("truetype"), url("./src/fonts/icomoon.woff?l7nvgq") format("woff"), url("./src/fonts/icomoon.svg?l7nvgq#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"], [data-type=icon] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

footer [data-type=icon] {
  font-size: 20px;
  color: #fff;
}

.icon-facebook::before {
  content: "";
}
.icon-facebook:hover::before {
  color: #3c5a99;
}

.icon-github::before {
  content: "";
}
.icon-github:hover::before {
  color: #333;
}

.icon-instagram::before {
  content: "";
}
.icon-instagram:hover::before {
  color: #833ab4;
}

.icon-linkedin::before {
  content: "";
}
.icon-linkedin:hover::before {
  color: #0077b5;
}

.icon-pinterest::before {
  content: "";
}
.icon-pinterest:hover::before {
  color: #e60023;
}

.icon-toutiao::before {
  content: "";
}
.icon-toutiao:hover::before {
  color: #ff373c;
}

.icon-twitter::before {
  content: "";
}
.icon-twitter:hover::before {
  color: #1da1f2;
}

.icon-weibo::before {
  content: "";
}
.icon-weibo:hover::before {
  color: #e6162d;
}

.icon-youtube::before {
  content: "";
}
.icon-youtube:hover::before {
  color: #f00;
}